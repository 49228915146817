import React from "react";
import {Link} from "react-router-dom";
/* We simply can use an array and loop and print each user */
const WokkenPage = () => {
    return (
        <div className="wokkenpage">

            <div className="nonhomepage-banner" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/images/wokken-banner.jpg'})`,
                backgroundRepeat: 'no-repeat',
                // width:'200vh'
                // backgroundSize: '100% 700px'
            }}/>


            <div className="container2 container">


                <div className="row">
                    <div className="col-sm-12">

                        <h2 className="subtitle-center restaurant-red">Chinees Wokken</h2>
                        <br/>
                        <p>
                            Bij het Chinees wokken stelt u zelf onbeperkt uw gerechten samen.

                            Bij het buffet en bij het tepanyakigrillen kan gekozen worden uit diverse vlees- en vis gerechten die op de bakplaat worden bereid.

                            Natuurlijk staan naast het barbecuen en het lopend buffet ook nog diverse Chinese, Indische en Westerse specialiteiten (ook Cantonese) op de menukaart.

                            Maak in ons restaurant (met klimaatbeheersing) voor een zeer vriendelijke tarief kennis met de Asiatische keuken.

                            Wij heten u van harte welkom!</p>
                    </div>
                </div>

                  <div className="row">
                    <div className="col-sm-8 price-box border">
                        <p>Onbeperkt Chinees Wokken alle gerechten inclusief tepanyakigriilen, barbecue, warm buffet, soepbuffet, salades, fruitbuffet, en ijsbuffet. Dat alles voor:</p>
                        <div className="wok-prices">
                        <p>€26,00 p.p.(vr-za-zo en feestdagen) excl. drank.<br/>

                        Van maandag t/m donderdag €24,00 p.p. excl. drank. <br/>

Kinderen €1,40 per leeftijdjaar t/m 11 jaar (excl. drank).</p>

                        <p>All-in vanaf 10 pers. maandag t/m donderdag  €34,00 p.p. (2 uur). <br/>

vrijdag, zaterdag, zondag en feestdag  €36,00 p.p. (2 uur). <br/>

                            <em>(elk half uur langer kost €2.00 euro extra)</em></p>
                            </div>
                        <p>tijdens kerstdagen en carnaval gelden andere prijzen</p>


                    </div>

                  </div>
                 <div className="reservebutton" >
                                <Link style={{"margin-bottom":"40px"}} to="/reserveren" className="btn btn-outline-danger cart-button">
                                    <div>Reserveren
                                    </div></Link>
                            </div>

                <div className="row">
                    <div className="col-sm-8">

                        <h6 className="restaurant-red">Gebaseerd op de Aziatische straatkeuken</h6>
                        <p>
                            Door het gunstige klimaat waren straatkeukens vroeger altijd te vinden in de directe nabijheid van de markt waar altijd verse produkten waren om te kunnen bereiden. De produkten waren seizoensgebonden, waardoor men elke keer weer nieuwe heerlijke combinaties maakte.

                            Deze veelzijdigheid aan combinaties maakt de aziatische keuken tot een van de populairste ter wereld.</p>

                    </div>
                    <div className="col-sm-4">
                        <img className=" image-fit-container center-images" src={process.env.PUBLIC_URL + '/images/wokken-2.jpg'} alt="Image of the restaurant"/>
                    </div>
                </div>

                <br/>

                <div className="row">

                    <div className="col-sm-4">
                        <img className=" image-fit-container center-images" src={process.env.PUBLIC_URL + '/images/buffet-3.jpg'} alt="Image of the restaurant"/>
                    </div>

                    <div className="col-sm-8">
                        <br/>
                        <h6 className="restaurant-red">Zelf smaken en gerechten combineren</h6>
                        <p>
                            Het geheim om optimaal van deze eetcultuur te genieten is het zelf combineren van ingredienten aan het buffet.
                            Daarna laat u ze door onze chefs met saus naar keuze voor u bereiden aan onze woktafel.

                            Dit wordt met enorme hitte en snelheid gedaan, waardoor de vitamines en de smaak optimaal bewaard blijven.
                        </p>
                    </div>

                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-12">
                        <h6 className="restaurant-red">Warm en koud buffet</h6>
                        Naast onze wokspecialiteiten kunt u bij ons ook kiezen uit een uitgebreid warm en koud buffet (dagelijks vers bereid). Diverse Aziatische en Westerse specialiteiten staan voor u klaar.
                        Het buffet bestaat uit:
                        <ul className="list-with-dots">
                            <li>Diverse soepen</li>
                            <li>Voorgerechten (ook sushi)</li>
                            <li>Warm buffet met : bami, nasi, Chineese dim sum hapjes</li>
                            <li>Vlees, vis en groenten</li>
                            <li>Diverse sauzen</li>
                            <li>Een uitgebreid fruitbuffet en een ijsbuffet met vele verschillende soorten schepijs maken uw maaltijd tot een feest!</li>
                        </ul>
                    </div>

                </div>
                <div className="row " >
                    <div className="col-sm-4 col-less-padding ">

                        <img className="image-fit-container center-images" src={process.env.PUBLIC_URL + '/images/buffet-1.jpg'} alt="Image of the restaurant"/>

                    </div>
                    <div className="col-sm-4 col-less-padding">

                        <img className="image-fit-container center-images" src={process.env.PUBLIC_URL + '/images/buffet-2.jpg'} alt="Image of the restaurant"/>

                    </div>

                    {/*<div className="col-sm-3 col-less-padding">*/}
                    {/*    <div >*/}
                    {/*        <img className="image-fit-container center-images" src={process.env.PUBLIC_URL + '/images/buffet-3.jpg'} alt="Image of the restaurant"/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-sm-4 col-less-padding">

                        <img  className="image-fit-container center-images" src={process.env.PUBLIC_URL + '/images/buffet-4.jpg'} alt="Image of the restaurant"/>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default WokkenPage;